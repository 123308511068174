.events-swiper {
    user-select: none;
}

.swiper-pagination-bullet {
    background: rgb(197, 197, 197);
    opacity: 0.6;
}

.swiper-pagination-bullet-active {
    /* background: blue; */
    opacity: 1;
}

.carousel {
    max-width: 90vw;
    display: flex;
    justify-content: center;
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 360px;
    max-width: 100%;
  }