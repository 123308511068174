/* marque code */

.marquee {
    overflow: hidden;
    position: relative;
    height: 250px;
  }
  
  .marquee .inner-container {
    height: 100%;
    display: block;
    width: 200%;
  
    position: absolute;
    overflow: hidden;
  
    animation: marquee 15s linear infinite;
  }
  
  .marquee span {
    float: left;
    width: 50%;
  }
  
  @keyframes marquee {
    0% {
      left: 0;
    }
  
    100% {
      left: -100%;
    }
  }