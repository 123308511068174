:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --text-gradient-1: linear-gradient(135deg, #fab14b 2.34%, #FC793D 100.78%); */
  /* --text-gradient: linear-gradient(135deg, #C52062 2.34%, #0a6efa 100.78%); */
  --paper-color: rgba(100, 100, 100, 0.1);
  /* --paper-color: #fff; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* text gradient */

.react-card-flip {
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.6rem;
  border-radius: 0.1rem;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.3);
  border-radius: 0.1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(150, 150, 150, 0.3);
}


* {
  /* overflow: scroll; */
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

*::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.inputStyle {
  width: 2.4rem !important;
  height: 2.4rem;
  margin: 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}